<template>
  <div class="store">
    <div class="box" v-if="detail">
      <div class="store-info">
        <div class="list">
          <div class="left">市场名称</div>
          <div class="right">
            <span>{{ detail.market_name }}</span>
          </div>
        </div>
        <div class="list">
          <div class="left">商铺个数</div>
          <div class="right">
            <span class="count">{{ detail.stall_number }}</span>
          </div>
        </div>
      </div>
      <template v-for="(item, index) of dataList" :key="index">
        <div class="item">
          <div class="item__code">
            <div>{{ item.merchants_stall_number }}</div>
          </div>
          <div class="item__info">
            <div>租赁时间</div>
            <div>{{ item.merchants_stall_start_time }}</div>
          </div>
          <div class="item__info">
            <div>面积</div>
            <div>{{ item.merchants_stall_size }}m²</div>
          </div>
          <div class="item__info">
            <div>租赁金额</div>
            <div>{{ item.merchants_stall_price }}元/月</div>
          </div>
        </div>
      </template>
    </div>
    <emptyItem v-else></emptyItem>
  </div>
</template>

<script>
export default {
  name: 'store',
  data() {
    return {
      detail: null,
      userInfo: null,
      dataList: []
    };
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem('propertyUserInfo'));
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$require.HttpPost('/h5/merchants/getMerchantsMessage').then((res) => {
        this.detail = res.data;
        this.dataList = res.data.contract_stall_number ?? [];
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.store {
  background-color: #f9f9f9;
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  .box {
    padding: 12px 24px;
    .store-info {
      background-color: #fff;
      padding: 0 30px;
      height: 150px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 10px;
      box-shadow: 0px 7px 9px 0px #00000004;
      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 28px;

        .right {
          span {
            color: #333333;
          }
          .count {
            color: #85c226;
          }
          .statusImg {
            width: 48px;
            height: 48px;
            margin-right: 15px;
          }
          .status {
            color: #85c226;
            &.gray {
              color: #b9b9b9;
            }
          }
          .license {
            width: 164px;
            height: 100px;
            margin-right: 15px;
          }
          .van-icon {
            color: #b9b9b9;
          }
        }
      }
    }
    .item {
      background-color: #fff;
      font-size: 28px;
      margin-top: 20px;
      border-radius: 10px;
      box-shadow: 0px 7px 9px 0px #00000004;
      padding: 0 30px;

      &__code {
        height: 75px;
        border-bottom: 1px solid #eaeaea;
        line-height: 75px;
        font-weight: bold;
      }
      &__info {
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: #000000;
      }
    }
  }
}
</style>
